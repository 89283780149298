import styled from "styled-components"

export const CardContainer = styled.div`
  background: #ffffff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`

export const CardHeader = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  color: #333333;
  margin-bottom: 14px;
`

export const InfoList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const InfoItem = styled.li`
  display: flex;
  align-items: center;
  white-space: nowrap;

  & span {
    font-size: 0.95rem;
    margin-left: 8px;
    color: #555555;
    font-weight: 408;
    margin-bottom: 4px;
  }
`

export const IconWrapper = styled.div`
  font-size: 1.1rem;
  color: ${({ color }) => color || "#000"};
`
