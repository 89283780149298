import React, { useEffect, useState } from "react"
import * as Styled from "./styles"
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts"
import { useResponsivePieChart } from "hooks/useResponsivePieChart"

// Données pour le Pie Chart
const fidelisationData = [
  { name: "Clients fidélisés", value: 450 },
  { name: "Clients non fidélisés", value: 200 },
]

// Couleurs pour les sections
const COLORS = ["#65376a", "#4285f4"]

// Données pour les CA (Chiffre d'affaires)
const revenueData = [
  { name: "CA clients fidélisés", value: "75 000 XOF" },
  { name: "CA clients non fidélisés", value: "25 000 XOF" },
  { name: "CA clients acquis en ligne", value: "35 000 XOF" },
  { name: "CA clients acquis au salon", value: "65 000 XOF" },
]

// Tooltip personnalisé
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0]
    return (
      <Styled.CustomTooltipContainer>
        {name}: {value}
      </Styled.CustomTooltipContainer>
    )
  }
  return null
}

// Composant Principal
export const ClientStats = () => {
  // Constante pour gérer la taille du PieChart
  const taille = useResponsivePieChart(400, 450,500)

  return (
    <Styled.SectionContainer>
      {/* Première Carte: Fidélisation */}
      <Styled.Card>
        <Styled.Title>Fidélisation des clients</Styled.Title>
        <Styled.StyledPieChart width={taille} height={250}>
          <Pie
            data={fidelisationData}
            dataKey='value'
            nameKey='name'
            cx='50%'
            cy='45%'
            outerRadius={100}
            labelLine={false}>
            {fidelisationData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </Styled.StyledPieChart>
        <Styled.Legend>
          <Styled.LegendItem>
            <Styled.ColorBox color='#65376a' /> Clients fidélisés: 450
          </Styled.LegendItem>
          <Styled.LegendItem>
            <Styled.ColorBox color='#4285f4' /> Clients non fidélisés: 200
          </Styled.LegendItem>
        </Styled.Legend>
      </Styled.Card>

      {/* Deuxième Carte: Chiffre d'affaires */}
      <Styled.Card>
        <Styled.Title>Chiffre d'affaires par type de client</Styled.Title>
        <Styled.RevenueList>
          {revenueData.map((item, index) => (
            <Styled.RevenueItem key={index}>
              <Styled.Name>{item.name}</Styled.Name>
              <Styled.Value>{item.value}</Styled.Value>
            </Styled.RevenueItem>
          ))}
        </Styled.RevenueList>
      </Styled.Card>
    </Styled.SectionContainer>
  )
}
