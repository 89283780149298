import { PieChart } from "recharts";
import styled from "styled-components";


// Composants Styled
export const ChartContainer = styled.div`
  width: 100%;  
  margin: 1.5rem auto; 
  border: 1px solid #ccc;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
`

export const ChartTitle = styled.h3`
  text-align: left;
  margin-bottom: 20px;
  font-size: 1.2em;
  font-weight: 500;
  color: #333;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`
export const StyledPieChart = styled(PieChart)`
  margin: ${({ centrer }) => centrer || "auto"};

`
// Style pour le Tooltip personnalisé
export const CustomTooltipContainer = styled.div`
  background-color: #65376a;
  display: flex;
  align-items: center;
  color: white;
  height: 39px;
  padding: 12px;
  border-radius: 6px;
  transform: translateY(-60px);
  border: 1px solid #fff;
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 500;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
`
export const LegendContainer = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 4rem;
  @media screen and (max-width: 768px) {
    left: 2rem;
    gap: 2rem;
  }
  justify-content: space-between;
`
export const LegendPrice = styled.div`
  font-size: 15px;
  font-weight: 550;
  @media screen and (min-width: 1300px) {
    position: absolute;
    right: -25rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
`
export const LegendValue = styled.div`
  line-height: ${({ lineHeight }) => lineHeight || "2.7rem"};
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`
