import { areaChartData } from "components/StatistiquesDashboard/Utils"
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import * as Styled from "./styles"

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Styled.TooltipContainer>
          <Styled.Label>
            {label}
          </Styled.Label>
          <Styled.Valeur>{`Revenu : ${payload[0]?.value || 0} XOF`}</Styled.Valeur>
      </Styled.TooltipContainer>
    )
  }
  return null
}



export const CustomAreaChart = () => {
  return (
    <Styled.ChartContainer>
      <Styled.Titre>Chiffre d'affaires total du salon</Styled.Titre>
      <ResponsiveContainer width='100%' height={300}>
        <AreaChart
          data={areaChartData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='#65376a' stopOpacity={0.3} />
              <stop offset='95%' stopColor='#65376a' stopOpacity={0.3} />
            </linearGradient>
          </defs>
          <XAxis dataKey='month' />
          <YAxis />
          <CartesianGrid strokeDasharray='3 3' />
          <Tooltip content={<CustomTooltip />} />
          <Area
            type='monotone'
            dataKey='value'
            stroke='#65376a'
            fill='url(#colorUv)'
          />
        </AreaChart>
      </ResponsiveContainer>
    </Styled.ChartContainer>
  )
}

