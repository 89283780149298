import { Cell, Legend, Pie, Tooltip } from "recharts"
import * as Styled from "./styles"

// Tooltip personnalisé
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0]
    return (
      <Styled.CustomTooltipContainer>
        <span>
          {name}: {value}%
        </span>
      </Styled.CustomTooltipContainer>
    )
  }
  return null
}

// Fonction pour formater la légende
const CustomLegend = ({ payload, styles = {}, isValue= true, isPrice= false }) => {
  return (
    <div>
      {payload.map((entry, index) => (
        <Styled.LegendContainer>
        <Styled.LegendValue
          lineHeight={styles.lineHeight}
          key={`legend-item-${index}`}>
          <div
            style={{
              backgroundColor: entry.payload.fill,
              width: 16,
              height: 16,
              marginRight: 10,
            }}></div>
            <span>
              {entry.payload.name}
              {isValue && `: ${entry.payload.value}%`}
            </span>
        </Styled.LegendValue>
        <Styled.LegendPrice>
          {isPrice && `${entry.payload?.price} XOF`}
        </Styled.LegendPrice>
          </Styled.LegendContainer>
      ))}
    </div>
  )
}


export const CustomPieChart = ({
  title,
  data,
  colors,
  largeur,
  PieWidth,
  styles = {},
  legendLineHeight,
  isValue,
  isPrice,
  disposition,
  alignement,
  PieHeight,
  CntreY,
  PieSize,
}) => {
  return (
    <Styled.ChartContainer>
      <Styled.ChartTitle>{title}</Styled.ChartTitle>
      <Styled.StyledPieChart
        centrer={styles.centrer}
        width={largeur}
        height={PieHeight || 300}>
        {/* Section graphique */}
        <Pie
          data={data}
          dataKey='value'
          nameKey='name'
          cx={PieWidth}
          cy={CntreY || "50%"}
          outerRadius={PieSize || 100}
          fill='#8884d8'
          label={false}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>

        {/* Légende avec pourcentage */}
        <Legend
          content={
            <CustomLegend
              isValue={isValue}
              isPrice={isPrice}
              styles={{ lineHeight: legendLineHeight }}
            />
          }
          layout='vertical'
          verticalAlign={alignement || "middle"}
          align={disposition || "right"}
        />

        {/* Tooltip personnalisé */}
        <Tooltip content={<CustomTooltip />} />
      </Styled.StyledPieChart>
    </Styled.ChartContainer>
  )
}
