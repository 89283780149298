import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import * as Styled from "./styles"

const COLORS = ["#65376a"] // Couleur pour les barres

// Tooltip personnalisé
const CustomTooltip = ({ active, payload, prefix }) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0].payload
    return (
      <Styled.CustomTooltipContainer>
        <span>
          {name}: {value} {prefix}
        </span>
      </Styled.CustomTooltipContainer>
    )
  }
  return null
}

// Composant principal
const CustomBarChart = ({title, data, prefix}) => {
  return (
    <Styled.ChartContainer>
      <Styled.ChartTitle>{title}</Styled.ChartTitle>
      <ResponsiveContainer width='100%' height={300}>
        <BarChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 10 }}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis domain={[0, 0]} />
          <Tooltip content={<CustomTooltip prefix={prefix} />} />
          <Bar dataKey='value' fill={COLORS[0]} barSize={160}>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Styled.ChartContainer>
  )
}

export default CustomBarChart
