import React from 'react'
import { CustomAreaChart } from './AreaChart'
import useMediaQuery from 'hooks/useMediaQuery'
import { elmaBreakpoints } from 'styles/theme'
import { KPICardContainer, KPIGroup } from '../RendezVous/CardsSection/styles'
import { Carousel } from 'antd'
import { CardClient } from '../Cards'
import { CAData, TableDetailCA } from '../Utils'
import DynamicTable from '../ClientsTable'
import { CustomPieChart } from '../RendezVous/PieChart'
import { useResponsivePieChart } from 'hooks/useResponsivePieChart'


// Données pour le graphique
const donneeContribution = [
  { name: "Emma", value: 25, },
  { name: "Liam", value: 20 },
  { name: "Olivia", value: 18 },
  { name: "Noah", value: 15 },
  { name: "Ava", value: 12 },
  { name: "Autres", value: 10 },
]
const donneeRepartition = [
  { name: "Hommes", value: 25, price: "140.523.201.000" },
  { name: "Femmes", value: 20, price: "200.000.000" },
  { name: "Enfents", value: 18, price: "124.215.185" },
]

// Couleurs des sections
const couleursContribution = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884D8","#82CA9D"]
const couleursRepartition = ["#0088FE", "#00C49F", "#FFBB28"]

export const DashboardCA = () => {

  const isUnderMD = useMediaQuery(`(max-width: ${elmaBreakpoints.md}px)`)
  const tailleContribution = useResponsivePieChart(400, 500, 700)
  const tailleRepartition = useResponsivePieChart(400, 600, 700)
  const alignement = useResponsivePieChart("bottom", "middle", "middle")
  const disposition = useResponsivePieChart('bottom', 'right', 'right')
  const CntreY = useResponsivePieChart("80%", "50%", "50%")
  const CntreYConribution = useResponsivePieChart("40%", "50%", "50%")
  const PieWidth = useResponsivePieChart("34%", "50%", "50%")
  const PieWidthConribution = useResponsivePieChart("28%", "50%", "50%")
  const height = useResponsivePieChart(450, 300, 300)

  return (
    <>
      <KPICardContainer>
        {isUnderMD ? (
          <Carousel
            dots
            arrows
            autoplay
            style={{ width: "100%", margin: "auto", paddingBottom: "1.8rem" }}>
            {CAData.map((data, index) => (
              <div key={index}>
                <CardClient {...data} />
              </div>
            ))}
          </Carousel>
        ) : (
          <KPIGroup>
            {CAData.map((data, index) => (
              <CardClient key={index} {...data} />
            ))}
          </KPIGroup>
        )}
      </KPICardContainer>
      {/* Le Area Chart */}
      <CustomAreaChart />
      {/* Le tableau des détails du CA */}
      <DynamicTable
        data={TableDetailCA}
        title="Détail du chiffre d'affaire par type"
      />
      {/* Le Pie Chart de contribution des collaborateurs */}
      <CustomPieChart
        title='Contribution des collaborateurs au CA'
        data={donneeContribution}
        largeur={tailleContribution}
        colors={couleursContribution}
        styles={{ centrer: "0" }}
        legendLineHeight={"1.9rem"}
        alignement={alignement}
        disposition={disposition}
        PieHeight={height}
        PieWidth={PieWidthConribution}
        CntreY={CntreYConribution}
      />
      {/* Le Pie Chart de Repartition du CA par sexe */}
      <CustomPieChart
        title=' Repartition du CA par sexe'
        data={donneeRepartition}
        largeur={tailleRepartition}
        colors={couleursRepartition}
        styles={{ centrer: "0" }}
        PieWidth={PieWidth}
        alignement={alignement}
        disposition={disposition}
        isValue={false}
        isPrice={true}
        PieHeight={height}
        CntreY={CntreY}
      />
    </>
  )
}
