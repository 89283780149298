import styled from "styled-components"
import { elmaBreakpoints } from "styles/theme"

export const KPICardContainer = styled.div`
  margin-top: 1.3rem;
`
export const KPIGroup = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  @media (max-width: ${elmaBreakpoints.lg}px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  @media (max-width: ${elmaBreakpoints.md}px) {
    grid-template-columns: 1fr;
  }
`
// Rendez-vous totaux
export const Flex = styled.div`
  width: 100%;
  justify-content: space-between;
  background: #fff;
  display: flex;
  align-items: center;
`

export const Title = styled.div`
  font-size: 13px;
  color: #6b7280;
`

export const Value = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #333;
`

export const Variation = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #22c55e;
`