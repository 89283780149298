import React from "react"
import * as Styled from "./styles"

const DynamicTable = ({ data, title }) => {
  return (
    <Styled.TableContainer>
      <Styled.TabTitle>{title}</Styled.TabTitle>
      <Styled.Table>
        <thead>
          <tr>
            {data.columns.map((col) => (
              <Styled.TableHeader key={col.id}>{col.name}</Styled.TableHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.rows.map((row, rowIndex) => (
            <Styled.TableRow key={rowIndex}>
              {data.columns.map((col) => (
                <Styled.TableCell key={col.id}>{row[col.id]}</Styled.TableCell>
              ))}
            </Styled.TableRow>
          ))}
        </tbody>
      </Styled.Table>
    </Styled.TableContainer>
  )
}

export default DynamicTable
