import { useState, useEffect } from "react"

export const useResponsivePieChart = (mobileSize, tabletSize, desktopSize) => {
  // Fonction pour déterminer la taille actuelle
  const getSize = () =>
    window.innerWidth < 768
      ? mobileSize
      : window.innerWidth >= 768 && window.innerWidth <= 1024
        ? tabletSize
        : desktopSize

  // État pour stocker la taille dynamique
  const [size, setSize] = useState(getSize())

  useEffect(() => {
    // Fonction qui sera appelée à chaque redimensionnement
    const handleResize = () => setSize(getSize())

    // Ajouter l'écouteur d'événement "resize"
    window.addEventListener("resize", handleResize)

    // Nettoyer l'écouteur lors du démontage du composant
    return () => window.removeEventListener("resize", handleResize)
  }, [mobileSize, tabletSize, desktopSize])

  return size // Retourner la taille dynamique
}
