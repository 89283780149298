import React from "react"
import * as Styled from "./styles"
import { FaMoneyBillWave, FaUsers, FaShoppingBag } from "react-icons/fa"
import { BsGraphUp, BsCalendarCheck, BsCalendarX, BsGraphUpArrow } from "react-icons/bs"
import { MdAccessTime, MdSubscriptions } from "react-icons/md"
import useSalonCurrency from "hooks/useSalonCurrency"
import { FiCalendar, FiDollarSign, FiShoppingBag } from "react-icons/fi"
import { TbUsers } from "react-icons/tb"

export const InfosCard = ({ name, data }) => {
  const currency = useSalonCurrency()

  return (
    <Styled.CardContainer>
      <Styled.CardHeader>{name}</Styled.CardHeader>
      <Styled.InfoList>
        <Styled.InfoItem>
          <Styled.IconWrapper color='#27ae60'>
            <FiDollarSign />
          </Styled.IconWrapper>
          <span>
            CA: {data.ca} {currency}
          </span>
        </Styled.InfoItem>
        <Styled.InfoItem>
          <Styled.IconWrapper color='#3498db'>
            <TbUsers />
          </Styled.IconWrapper>
          <span>Prestations: {data.prestations}</span>
        </Styled.InfoItem>
        <Styled.InfoItem>
          <Styled.IconWrapper color='#9b59b6'>
            <FiShoppingBag />
          </Styled.IconWrapper>
          <span>
            Panier moyen: {data.panierMoyen} {currency}
          </span>
        </Styled.InfoItem>
        <Styled.InfoItem>
          <Styled.IconWrapper color='#e74c3c'>
            <BsGraphUpArrow />
          </Styled.IconWrapper>
          <span>Taux de fidélisation: {data.tauxFidelisation}%</span>
        </Styled.InfoItem>
        <Styled.InfoItem>
          <Styled.IconWrapper color='#3458db'>
            <FiCalendar />
          </Styled.IconWrapper>
          <span>RDV: {data.rdv}</span>
        </Styled.InfoItem>
        <Styled.InfoItem>
          <Styled.IconWrapper color='#e67e22'>
            <MdAccessTime />
          </Styled.IconWrapper>
          <span>Sans RDV: {data.sansRdv}</span>
        </Styled.InfoItem>
        <Styled.InfoItem>
          <Styled.IconWrapper color='#e74c3e'>
            <MdSubscriptions />
          </Styled.IconWrapper>
          <span>Abonnements: {data.abonnements}</span>
        </Styled.InfoItem>
      </Styled.InfoList>
    </Styled.CardContainer>
  )
}
