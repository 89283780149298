import styled from "styled-components"
import { elmaBreakpoints } from "styles/theme"

export const TableContainer = styled.div`
  overflow-x: auto;
  border-radius: 10px;
  margin: 20px 0;
  border: 1px solid #ddd;
  padding: 20px;
`
export const TabTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  text-align: start;
`

export const Table = styled.table`
  width: 100%;
  text-align: left;
  border-radius: 10px;
  padding: 10px;
  @media screen and (max-width: ${elmaBreakpoints.md}px) {
    width: 800px;
  }
`

export const TableHeader = styled.th`
  padding: 10px;
  background-color: #f3f4f6;
  font-weight: bold;
  font-size: 16px;
`

export const TableCell = styled.td`
  padding: 10px;
  white-space: nowrap;
  font-weight: 410;
`

export const TableRow = styled.tr`
  background-color: #f9fafb;
  &:nth-child(even) {
    background-color: #fff;
  }
`
