import { PieChart } from "recharts"
import styled from "styled-components"
import { elmaBreakpoints } from "styles/theme"

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 1.5rem;

  @media screen and (min-width:1111px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const Card = styled.div`
  flex: 1 ;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 20px;
`

export const Title = styled.h3`
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 20px;
`
export const StyledPieChart = styled(PieChart)`
  margin: ${({ centrer }) => centrer || "auto"};

`

// Style pour la légende
export const Legend = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px; 
`

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #333;
`

export const ColorBox = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${(props) => props.color};
  margin-right: 8px;
`

// Style pour les chiffres d'affaires
export const RevenueList = styled.ul`
  list-style: none;
  padding: 0;
`

export const RevenueItem = styled.li`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  font-size: 1rem;
  color: #333;
  line-height: 0.8rem;
  margin-bottom: 10px;
`

export const Name = styled.p`
  font-size: 15px ;
  font-weight: 500;
`
export const Value = styled.p`
  font-size: 17px ;
  font-weight: bold;
`
// Tooltip personnalisé
export const CustomTooltipContainer = styled.div`
  background: #65376a;
  color: #fff;
  padding: 8px 10px;
  border-radius: 4px;
`
