import { SkeletonCard } from "components/Dashboard/utils"
import { Card, CardClient } from "../Cards"
import Chart, { CustomTooltip1 } from "../Chart"
import { ChartContainer } from "../Chart/styles"
import { Revenu } from "../Revenu"
import * as Styled from "../styles"
import { TopServices } from "../TopServices"
import useMediaQuery from "hooks/useMediaQuery"
import { elmaBreakpoints } from "styles/theme"
import { Carousel } from "antd"
import { kpiClientData } from "../Utils"
import { KPICardContainer, KPIGroup } from "../RendezVous/CardsSection/styles"

export const TableauDeBord = ({ salonData, isLoading }) => {
    const isUnderMD = useMediaQuery(`(max-width: ${elmaBreakpoints.md}px)`)

  return (
    <>
      <Styled.Flex>
        <Styled.GroupLeft>
          {isUnderMD ? (
            <Carousel
              dots
              arrows
              autoplay
              style={{
                width: "100%",
                margin: "auto",
                paddingBottom: "0.5rem",
              }}>
              <Card
                title='Rendez-vous'
                value={salonData?.appointments_count?.current}
                data={salonData?.appointments_count}
              />
              <Card
                title='Ventes directes'
                value={salonData?.direct_sales_count?.current}
                data={salonData?.direct_sales_count}
              />
            </Carousel>
          ) : (
            <Styled.KPIContainer>
              <Card
                title='Rendez-vous'
                value={salonData?.appointments_count?.current}
                data={salonData?.appointments_count}
              />
              <Card
                title='Ventes directes'
                value={salonData?.direct_sales_count?.current}
                data={salonData?.direct_sales_count}
              />
            </Styled.KPIContainer>
          )}

          {/* Chart Graphique */}
          <ChartContainer>
            <Chart
              data={salonData}
              tooltip={<CustomTooltip1 />}
              title="Chiffre d'affaire"
            />
          </ChartContainer>
          {/* Section des top services & Performances des employes*/}
          {isLoading ? <SkeletonCard /> : <TopServices data={salonData} />}
        </Styled.GroupLeft>
        <Styled.GroupRight>
          {isLoading ? <SkeletonCard /> : <Revenu data={salonData} />}
        </Styled.GroupRight>
      </Styled.Flex>

      {/* Section KPI Clients*/}
        {/* <KPICardContainer>
          {isUnderMD ? (
            <Carousel dots arrows autoplay
              style={{width: "100%", margin: "auto", paddingBottom: "1.8rem",
              }}>
              {kpiClientData.map((data, index) => (
                <div key={index}><CardClient {...data} /></div>
              ))}
            </Carousel>
          ) : (
            <KPIGroup>
              {kpiClientData.map((data, index) => (
                <div key={index}><CardClient {...data} /></div>
              ))}
            </KPIGroup>
          )}
        </KPICardContainer> */}
    </>
  )
}
