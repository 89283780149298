import { useSalonQuery } from "actions/dashboard"
import { Result } from "antd"
import { Context } from "contexts/Dashboard"
import { TAB_IDS, tabs } from "enums/constants"
import _ from "lodash"
import { useContext, useState } from "react"
import { Date } from "./Date"
import { RendezVous } from "./RendezVous"
import * as Styled from "./styles"
import { TableauDeBord } from "./TableauDeBord"
import TabNavigation from "./TabNavigation"
import { ClientDashboard } from "./ClientDashboard"
import { DashboardCA } from "./DashboardCA"
import { Collaborateur } from "./Collaborateur"

const StatistiquesDashboard = ({ scrollAble }) => {
  const [state] = useContext(Context)
  const [activeTab, setActiveTab] = useState("dashboard")

  const { data: salonData, status } = useSalonQuery({
    period: state.dashboardPeriod,
    date: state.dashboardDate,
  })
  const isLoading = status === "loading" || status === "idle"
  const isError = status === "error"
  const content = () => {
    if (_.isEmpty(salonData?.appointments) && !isLoading && !isError) {
      return (
          <Result status='warning' title='Ne dispose pas encore des données' />
      )
    } else if (isError) {
      return (
        <Result
          status='500'
          title='500'
          subTitle={`Désolé, quelque chose s'est mal passé.`}
        />
      )
    } else {
      return (
        <Styled.Global scrollable={scrollAble}>
          {/* Navigation par onglets */}
          <Styled.Group>
            <TabNavigation
              tabs={tabs}
              activeTab={activeTab}
              onTabChange={setActiveTab}
            />
          </Styled.Group>

          <>
            <Date /> {/*Le filtre par Dates */}
          </>

          {/* Contenu dynamique basé sur l'onglet actif */}
          <Styled.Container>
            {activeTab === TAB_IDS.DASHBOARD && (
              <TableauDeBord salonData={salonData} isLoading={isLoading} />
            )}
            {activeTab === TAB_IDS.APPOINTMENTS && (
              <RendezVous salonData={salonData} isLoading={isLoading} />
            )}
            {activeTab === TAB_IDS.CLIENTS &&<ClientDashboard/>}
            {activeTab === TAB_IDS.BUSINESS && (
              <DashboardCA/>
            )}
            {activeTab === TAB_IDS.COLLABORATORS && (
              <Collaborateur/>
            )}
          </Styled.Container>
        </Styled.Global>
      )
    }
  }

  return content()
}

export default StatistiquesDashboard
