import { Carousel } from "antd"
import { SkeletonCard } from "components/Dashboard/utils"
import useMediaQuery from "hooks/useMediaQuery"
import {
  Line,
} from "recharts"
import { elmaBreakpoints } from "styles/theme"
import { CardClient } from "../Cards"
import Chart, { CustomTooltip2 } from "../Chart"
import { ChartContainer, Conteneur, Legende, RDV, Revenu } from "../Chart/styles"
import AmountDisplay from "../Revenu/amountDisplay"
import { Container } from "../Revenu/styles"
import * as Styled from "../styles"
import { kpiRdvTotaux, kpiTickets } from "../Utils"
import { CardsSection } from "./CardsSection"
import { KPICardContainer, KPIGroup } from "./CardsSection/styles"
import { CustomPieChart } from "./PieChart"
import { useResponsivePieChart } from "hooks/useResponsivePieChart"


// Données pour le graphique
const data = [
  { name: "Femmes", value: 60 },
  { name: "Hommes", value: 35 },
  { name: "Enfants", value: 5 },
]

// Couleurs des sections
const COLORS = ["#65376a", "#4285f4", "#fbbc05"]

export const RendezVous = ({ salonData, isLoading }) => {

  const isUnderMD = useMediaQuery(`(max-width: ${elmaBreakpoints.md}px)`)
  const taille = useResponsivePieChart(400, 500, 800)
  const PieWidthConribution = useResponsivePieChart("28%", "50%", "50%")
  const height = useResponsivePieChart(380, 300, 300)
  const alignement = useResponsivePieChart("bottom", "middle", "middle")
  const disposition = useResponsivePieChart('bottom', 'right', 'right')
  const CntreYConribution = useResponsivePieChart("50%", "50%", "50%")


  return (
    <>
      <Styled.Flex>
        <Styled.GroupLeft>
          {/* Chart Graphique */}
          <ChartContainer>
            <Chart
              data={salonData}
              rendezVous={
                <Line
                  yAxisId='right'
                  type='monotone'
                  dataKey='appointments'
                  stroke='#dfcfec'
                  strokeWidth={3}
                  dot={false}
                  activeDot={{
                    r: 5,
                    stroke: "#dfcfec",
                    fill: "white",
                  }}
                />
              }
              tooltip={<CustomTooltip2 />}
              title='Évolution des rendez-vous et du CA'
              legende={
                <Legende>
                  <Conteneur>
                    <RDV />
                    <p>Rendez-vous</p>
                  </Conteneur>
                  <Conteneur>
                    <Revenu />
                    <p>Revenu</p>
                  </Conteneur>
                </Legende>
              }
            />
          </ChartContainer>
        </Styled.GroupLeft>

        <Styled.GroupRight>
          {isLoading ? (
            <SkeletonCard />
          ) : (
            <>
              <Styled.H1>Chiffre d'affaires total des rendez-vous</Styled.H1>
              <Container>
                <Styled.H2>Revenu généré</Styled.H2>
                <AmountDisplay amount={salonData?.total_ca} />
              </Container>
            </>
          )}
        </Styled.GroupRight>
      </Styled.Flex>
      {/* Les Cartes rdv, reservations & ventes */}
      <CardsSection />
      {/* Le PieChart pour les répartitions par genre */}
      <CustomPieChart
        title='Répartition des réservations par genre'
        data={data}
        colors={COLORS}
        largeur={taille}
        alignement={alignement}
        disposition={disposition}
        PieHeight={height}
        PieWidth={PieWidthConribution}
        CntreY={CntreYConribution}
      />
      {/* Les Cartes des tickets */}
      <KPICardContainer>
        {isUnderMD ? (
          <Carousel
            dots
            arrows
            autoplay
            style={{ width: "100%", margin: "auto", paddingBottom: "1.8rem" }}>
            {kpiTickets.map((data, index) => (
              <div key={index}>
                <CardClient {...data} />
              </div>
            ))}
          </Carousel>
        ) : (
          <KPIGroup>
            {kpiTickets.map((data, index) => (
              <CardClient key={index} {...data} />
            ))}
          </KPIGroup>
        )}
      </KPICardContainer>
    </>
  )
}
