import React from 'react'
import CustomBarChart from './BarChart'
import { KPICardContainer, KPIGroup } from '../RendezVous/CardsSection/styles'
import { Carousel } from 'antd'
import { CardClient } from '../Cards'
import { kpiClientRdvData, TableData } from '../Utils'
import useMediaQuery from 'hooks/useMediaQuery'
import { elmaBreakpoints } from 'styles/theme'
import { ClientStats } from './ClientStats'
import DynamicTable from '../ClientsTable'

export const ClientDashboard = () => {
  const isUnderMD = useMediaQuery(`(max-width: ${elmaBreakpoints.md}px)`)

  // Les Données 
  const data = [
    { name: "Jan", value: 30 },
    { name: "Fév", value: 35 },
    { name: "Mar", value: 40 },
    { name: "Avr", value: 37 },
    { name: "Mai", value: 45 },
    { name: "Juin", value: 50 },
    { name: "Juil", value: 55 },
    { name: "Aoû", value: 60 },
    { name: "Sep", value: 58 },
    { name: "Oct", value: 50 },
    { name: "Nov", value: 45 },
    { name: "Déc", value: 43 },
  ]

  return (
    <>
      <CustomBarChart title="Acquisition de clients par mois" prefix="clients" data={data} />
      <KPICardContainer>
        {isUnderMD ? (
          <Carousel
            dots
            arrows
            autoplay
            style={{ width: "100%", margin: "auto", paddingBottom: "1.8rem" }}>
            {kpiClientRdvData.map((data, index) => (
              <div key={index}>
                <CardClient {...data} />
              </div>
            ))}
          </Carousel>
        ) : (
          <KPIGroup>
            {kpiClientRdvData.map((data, index) => (
              <CardClient key={index} {...data} />
            ))}
          </KPIGroup>
        )}
      </KPICardContainer>
      <ClientStats />

      <DynamicTable data={TableData} title='Top 10 des meilleurs clients'/>
    </>
  )
}
