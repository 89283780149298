
import { FiUsers } from "react-icons/fi"
import { LuUserPlus, LuGlobe, LuDollarSign, LuCalendar } from "react-icons/lu"
import { SlBadge } from "react-icons/sl"
import { LegendValue } from "../RendezVous/PieChart/styles"

//============ Données des cartes KPI statistiques =========//
export const kpiData = [
  { title: "Rendez-vous", value: "13,0", variation: 10 },
  { title: "Ventes directes", value: "28,0", variation: 20 },
  { title: "Forfaits et packs", value: "00,0", variation: 5 },
]

//============ Données des cartes KPI clients =========//
export const kpiClientData = [
  { title: "Total clients", value: 500, icon: <FiUsers /> },
  { title: "Nouveaux clients", value: 50, icon: <LuUserPlus /> },
  { title: "Clients réguliers", value: 200, icon: <SlBadge /> },
]
//============ Données des cartes KPI clients =========//
export const kpiClientRdvData = [
  { title: "Clients aquis avec RDV", value: 350, icon: <FiUsers /> },
  { title: "Clients aquis sans RDV", value: 120, icon: <LuUserPlus /> },
  { title: "Clients aquis en ligne", value: 180, icon: <LuGlobe /> },
]

//===============Données cartes des collabortateurs ============
export const collaboratorsData = [
  {
    name: "Emma",
    data: {
      ca: "45.000",
      prestations: 300,
      panierMoyen: "15.000",
      tauxFidelisation: 75,
      rdv: 250,
      sansRdv: 50,
      abonnements: 15,
    },
  },
  {
    name: "Liam",
    data: {
      ca: "40.000",
      prestations: 280,
      panierMoyen: "14.000",
      tauxFidelisation: 70,
      rdv: 220,
      sansRdv: 60,
      abonnements: 12,
    },
  },
  {
    name: "Olivia",
    data: {
      ca: "38.000",
      prestations: 260,
      panierMoyen: "14.000",
      tauxFidelisation: 72,
      rdv: 200,
      sansRdv: 60,
      abonnements: 10,
    },
  },
  {
    name: "Noah",
    data: {
      ca: "35.000",
      prestations: 240,
      panierMoyen: "14.000",
      tauxFidelisation: 68,
      rdv: 180,
      sansRdv: 60,
      abonnements: 8,
    },
  },
  {
    name: "Ava",
    data: {
      ca: "32.000",
      prestations: 220,
      panierMoyen: "14.000",
      tauxFidelisation: 65,
      rdv: 160,
      sansRdv: 60,
      abonnements: 7,
    },
  },
]


//========= Données pour la table Top 10 meilleurs clients =========

export const TableData = {
  columns: [
    { id: 1, name: "Nom du cleint" },
    { id: 2, name: "Nombre de rendez-vous" },
    { id: 3, name: "Chiffre d'affaire" },
  ], // Titre des colonnes avec des ids uniques
  rows: [
    { 1: "Emma Dubois", 2: 15, 3: "150 000 XOF" },
    { 1: "Thomas Martin", 2: 12, 3: "135 000 XOF" },
    { 1: "Sophie Lefebvre", 2: 10, 3: "120 000 XOF" },
    { 1: "Lucas Bernard", 2: 9, 3: "110 000 XOF" },
    { 1: "Chloé Petit", 2: 8, 3: "95 000 XOF" },
    { 1: "Antoine Roux", 2: 7, 3: "90 000 XOF" },
    { 1: "Camille Leroy", 2: 7, 3: "85 000 XOF" },
    { 1: "Mathieu Moreau", 2: 6, 3: "80 000 XOF" },
    { 1: "Julie Girard", 2: 6, 3: "75 000 XOF" },
    { 1: "Nicolas Fournier", 2: 5, 3: "70 000 XOF" },
  ],
}

export const CustomLegend = ({ payload, styles = {} }) => {
  return (
    <div>
      {payload.map((entry, index) => (
        <LegendValue
          lineHeigth={styles.lineHeigth}
          key={`legend-item-${index}`}>
          <div
            style={{
              backgroundColor: entry.payload.fill,
              width: 16,
              height: 16,
              marginRight: 10,
            }}></div>
          <span>
            {entry.payload.name}: {entry.payload.value}%
          </span>
        </LegendValue>
      ))}
    </div>
  )
}
//========= Données pour la table Performence des collaborateurs =========
export const TableCollaborateurData = {
  columns: [
    { id: 1, name: "Nom" },
    { id: 2, name: "CA" },
    { id: 3, name: "Prestations" },
    { id: 4, name: "Panier moyen" },
    { id: 5, name: "Taux de fidélisation" },
    { id: 6, name: "RDV" },
    { id: 7, name: "Sans RDV" },
    { id: 8, name: "Abonnements" },
    { id: 9, name: "Forfaits/packs" },
    { id: 10, name: "Cartes-cadeaux" },
    { id: 11, name: "Produits" },
  ], // Titre des colonnes avec des ids uniques
  rows: [
    { 1: "Emma", 2: "45 000 XOF", 3: 300, 4:"15 000 XOF", 5:"75%", 6:250, 7:50, 8:15, 9:20, 10:10, 11:30},
    { 1: "Liam", 2: "40 000 XOF", 3: 280, 4:"14 300 XOF", 5:"70%", 6:220, 7:60, 8:12, 9:18, 10:8, 11:25},
    { 1: "Olivia", 2: "38 000 XOF", 3: 260, 4:"14 200 XOF", 5:"72%", 6:200, 7:60, 8:10, 9:15, 10:12, 11:28},
    { 1: "Noah", 2: "35 000 XOF", 3: 240, 4:"14 000 XOF", 5:"68%", 6:180, 7:60, 8:8, 9:12, 10:7, 11:22},
    { 1: "Ava", 2: "32 000 XOF", 3: 220, 4:"13 500 XOF", 5:"65%", 6:160, 7:60, 8:7, 9:10, 10:6, 11:20},
  ],
}







//========= Données pour la table Détail chiffre d'affaire par type =========

export const TableDetailCA = {
  columns: [
    { id: 1, name: "Type" },
    { id: 2, name: "Montant" },
    { id: 3, name: "Pourcentage" },
  ], // Titre des colonnes avec des ids uniques
  rows: [
    { 1: "Rendez-vous", 2: "150 000 XOF", 3: "50%" },
    { 1: "Vente directe", 2: "60 000 XOF", 3: "20%" },
    { 1: "Abonnement", 2: "30 000 XOF", 3: "10%" },
    { 1: "Carte-cadeau", 2: "15 000 XOF", 3: "5%" },
    { 1: "Forfait", 2: "30 000 XOF", 3: "10%" },
    { 1: "Produit", 2: "15 000 XOF", 3: "5%" },
  ],
}



//============ Données des cartes KPI Rendez-vous =========//
export const kpiRdvData = [
  { title: "Rendez-vous pris", value: 500, icon: <LuCalendar /> },
  { title: "Ventes directes",  showCurrency: true, value: 175000, icon: <LuDollarSign /> },
  { title: "Réservations en ligne", value: 200, icon: <LuGlobe /> },
  { title: "Nouveaux clients", value: 150, icon: <LuUserPlus /> },
]
//============ Données des cartes KPI Rendez-vous totaux =========//
export const kpiRdvTotaux = [
  {
    title: "Réservations en ligne",
    value: "40%",
    styles: {
      titleFontSize: "17px",
      valueFontSize: "22px",
      titleFontWeight: "400",
    },
    description: "Des rendez-vous totaux",
  },
  {
    title: "Rendez-vous manqués",
    value: "5%",
    styles: {
      titleFontSize: "17px",
      valueFontSize: "22px",
      titleFontWeight: "400",
    },
    description: "Des rendez-vous totaux",
  },
  {
    title: "Rendez-vous annulés",
    value: "8%",
    styles: {
      titleFontSize: "17px",
      valueFontSize: "22px",
      titleFontWeight: "400",
    },
    description: "Des rendez-vous totaux",
  },
]

//============ Données des cartes KPI Rendez-vous totaux =========//
export const kpiTickets = [
  {
    title: "Ticket moyen (avec réservation)",
    value: 546000,
    styles: {
      titleFontSize: "17px",
      valueFontSize: "20px",
      titleFontWeight: "500",
    },
    showCurrency: true,
  },
  {
    title: "Ticket moyen (sans réservation)",
    value: 75000,
    styles: {
      titleFontSize: "16px",
      valueFontSize: "20px",
      titleFontWeight: "500",
    },
    showCurrency: true,
  },
]

//============ Données des cartes KPI Rendez-vous totaux =========//
export const CAData = [
  {
    title: "CA Aujourd'hui",
    value: 125000,
    styles: {
      titleFontSize: "16px",
      valueFontSize: "17px",
      titleFontWeight: "400",
    },
    showCurrency: true,
  },
  {
    title: "CA Ce Mois",
    value: 2800000,
    styles: {
      titleFontSize: "16px",
      valueFontSize: "17px",
      titleFontWeight: "400",
    },
    showCurrency: true,
  },
  {
    title: "CA Cette Année",
    value: 290000000,
    styles: {
      titleFontSize: "16px",
      valueFontSize: "17px",
      titleFontWeight: "400",
    },
    showCurrency: true,
  },
  {
    title: "Panier Moyen",
    value: 80600,
    styles: {
      titleFontSize: "16px",
      valueFontSize: "17px",
      titleFontWeight: "400",
    },
    showCurrency: true,
  },

]
//============ Données du AREA CHART ============
export const areaChartData = [
  { month: "Jan", value: 16000 },
  { month: "Fév", value: 18000 },
  { month: "Mar", value: 22000 },
  { month: "Avr", value: 21000 },
  { month: "Mai", value: 24000 },
  { month: "Juin", value: 26000 },
  { month: "Juil", value: 28000 },
  { month: "Aoû", value: 30000 },
  { month: "Sep", value: 27000 },
  { month: "Oct", value: 25000 },
  { month: "Nov", value: 24000 },
  { month: "Déc", value: 23000 },
]


//============ Données des Services =========//
export const topServices = [
  { label: "Massage Suédois", value: "30.000" },
  { label: "Massage aux pierres chaudes", value: "45.675" },
  { label: "Réflexologie", value: "87.987" },
]

//============ Performances des Employers =========//
export const performancesEmployes = [
  { label: "Emma", value: "30.000" },
  { label: "Liam", value: "45.675" },
  { label: "Olivia", value: "87.987" },
]

//============ Jours et Mois pour le chart=========//
const MONTHS = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Sept",
  "Oct",
  "Nov",
  "Déc",
]

const DAYS = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
]


  //====== Déterminer le type de période
  const determinePeriodType = (revenueByPeriod) => {
    // Vérifier le nombre maximum de jours
    const maxDay = Math.max(...revenueByPeriod.map((item) => item.day || 0))
    if (maxDay > 8) return "month"
    if (maxDay > 0) return "week"
    return "year"
  }

  //====== Transformer les données pour le graphique
   export const transformChartData = (revenueByPeriod) => {
    const periodType = determinePeriodType(revenueByPeriod)

    let fullPeriodData
    switch (periodType) {
      case "week":
        // Pour une semaine, utiliser les jours de la semaine
        fullPeriodData = Array.from({ length: 7 }, (_, index) => ({
          label: DAYS[index],
          day: index + 1,
          value: 0,
        }))
        break
      case "month":
        // Pour un mois, utiliser les jours de 1 à 31
        fullPeriodData = Array.from({ length: 31 }, (_, index) => ({
          label: `${index + 1}`,
          day: index + 1,
          value: 0,
        }))
        break
      case "year":
        // Pour une année, utiliser les mois
        fullPeriodData = Array.from({ length: 12 }, (_, index) => ({
          label: MONTHS[index],
          month: index + 1,
          value: 0,
        }))
        break
    }

    // Fusionner avec les données réelles
    revenueByPeriod.forEach((item) => {
      const matchIndex = fullPeriodData.findIndex(
        (data) =>
          (item.day !== undefined && data.day === item.day) ||
          (item.month !== undefined && data.month === item.month),
      )

      if (matchIndex !== -1) {
        fullPeriodData[matchIndex].value = parseFloat(item.total)
      }
    })

    return fullPeriodData
  }


