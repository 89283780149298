import React from "react";
import * as Styled from "./styles";
import useSalonCurrency from "hooks/useSalonCurrency";
import { getPercentegeDiff } from "utils";

export const Card = ({ title, value, data }) => {
  return (
    <Styled.CardContainer>
      <Styled.Flex>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Variation>
          {`${data?.current > data?.prev ? "+" : "-"}${
            getPercentegeDiff(data?.current, data?.prev) || "N/A"
          }% `}
        </Styled.Variation>
      </Styled.Flex>
      <Styled.Value>{value}</Styled.Value>
    </Styled.CardContainer>
  )
};


export const CardClient = ({
  title,
  value,
  icon,
  showCurrency = false,
  styles = {},
  description
}) => {
    const currency = useSalonCurrency()
  return (
    <Styled.KPICard bgColor={styles.bgColor}>
      <Styled.CardHeader>
        <Styled.KPITitle
          color={styles.titleColor}
          fontSize={styles.titleFontSize}
          fontWeight={styles.titleFontWeight}>
          {title}
        </Styled.KPITitle>
        <Styled.IconWrapper size={styles.iconSize} color={styles.iconColor}>
          {icon}
        </Styled.IconWrapper>
      </Styled.CardHeader>
      <Styled.KPIValue
        fontSize={styles.valueFontSize}
        fontWeight={styles.valueFontWeight}
        color={styles.valueColor}>
        {value} {showCurrency && ` ${currency}`}
      <Styled.Description>{description}</Styled.Description>
      </Styled.KPIValue>
    </Styled.KPICard>
  )
}



export const CACard = ({ title, value }) => {
    const currency = useSalonCurrency()
  return (
    <Styled.CAFlex>
      <Styled.CATitle>{title}</Styled.CATitle>
      <Styled.CAValue>
        {value}&nbsp;{currency}
      </Styled.CAValue>
    </Styled.CAFlex>
  )
}

