import { Col, Modal, Result, Row } from 'antd'
import React, { useEffect, useReducer, useState } from 'react'
import ServicePopulaireCardItem from './ServicePopulaireCardItem'
import * as Styled from "../styles"
import Loading from 'components/Loading'
import GridList from 'react-flexible-list'
import { convertMinutesTohours } from 'utils'
import { useServicesContext } from 'contexts/Services/ServicesProvider'
import { SERVICES_REDUCER_ACTION_TYPE } from 'contexts/Services/reducer'
import VenteRapideModal from './VenteRapideModal'
import { useQueryClient } from 'react-query'
import useSalonId from 'hooks/useSalonId'
import { useSalonServices } from 'hooks/useSalonServices'

const ServicesPopulaires = () => {
    const { isLoading, isError, servicesState, dispatchServices } = useServicesContext()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const queryClient = useQueryClient()
    const salonId = useSalonId()
    const { salonsServices: data } = useSalonServices()

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    
    useEffect(() => {
        // ! we invalidate the cache on mount
        queryClient.invalidateQueries(["services", salonId]);
    }, [])
    
    useEffect(() => {
        // ! we set the initial state of servicesReducer on refetch
        dispatchServices({
            type: SERVICES_REDUCER_ACTION_TYPE.SET_SETVICES,
            payload: data
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])



    if (isLoading) {
        return <Loading />
    }
    if (isError) {
        return <Result status={"error"} title="Une erreur est survenu" />
    }
    return (
        <div style={{ marginTop: 10 }}>
            {isModalOpen && <VenteRapideModal handleCancel={handleCancel} handleOk={handleOk} isModalOpen={isModalOpen} />}
            <GridList
                py={20}
                resourceData={servicesState.salonsServices}
                resourceItem={(service) =>
                    <ServicePopulaireCardItem
                        onClick={(service) => {
                            showModal(true)
                            dispatchServices({
                                type: SERVICES_REDUCER_ACTION_TYPE.SET_SELECTED_SERVICE,
                                payload: service
                            })
                        }}
                        removeSelectedService={(serviceId) => {
                            dispatchServices({
                                type: SERVICES_REDUCER_ACTION_TYPE.REMOVE_SELECTED_SERVICE,
                                payload: serviceId
                            })
                        }}
                        showModal={showModal}
                        service={service}
                        description={(duration) => <>
                            <Styled.ServiceDescription>{convertMinutesTohours(duration)}</Styled.ServiceDescription>
                        </>
                        }
                    />
                }
                gap={5}
                cardWidth={180}
                emptyComponent={() => <Result status={"warning"} title="Pas de services" />}
                minHeight={200}
            />
        </div>
    )
}

export default ServicesPopulaires