
import styled from "styled-components"
import { elmaBreakpoints } from "styles/theme"

export const Container = styled.div`
  background: white;
  padding: 20px;
  @media screen and (max-width: ${elmaBreakpoints.md}px) {
    width: 700px;
  }
`

export const ChartContainer = styled.div`
  
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  overflow-x: auto;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

export const Title = styled.h2`
  font-size: 17px;
  color: #333;
  font-weight: 600;
`
export const RDV = styled.div`
  width: 20px;
  border-radius: 50%;
  background-color: #dfcfec;
  height: 20px;
`
export const Revenu = styled.div`
  width: 20px;
  border-radius: 50%;
  background-color: #65376a;
  height: 20px;
`
export const Conteneur = styled.div`
  display: flex;
  gap: 6px;
`
export const Legende = styled.div`
  display: flex;
  gap: 10px;
  margin-right: 20px;
`

export const YearSelect = styled.select`
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid #ddd;
  background: white;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    border-color: #65376A;
  }
`

export const ChartWrapper = styled.div`
  height: 280px;
  position: relative;
`

export const TooltipContainer = styled.div`
  background-color: #65376A;
  display: flex;
  align-items: center;
  color: white;
  height: 36px;
  padding: 4px 12px 0 12px;
  border-radius: 6px;
  transform: translateY(-80px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`
export const TooltipContainer2 = styled.div`
  background-color: #65376A;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
  height: 80px;
  padding:  7px 10px 10px 10px;
  border-radius: 6px;
  transform: translateY(-80px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`

export const Rdv = styled.p`
  padding-top: 4px;
  font-size: 14px;
  font-weight: 400;
`
export const Valeur = styled.p`
  padding-bottom: 18px;
  padding-top: 1px;
  font-size: 14px;
  font-weight: 400;
`
export const Valeur1 = styled.p`
  padding-top: 11px;
  font-size: 14px;
  font-weight: 400;
`
