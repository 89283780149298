import useSalonCurrency from 'hooks/useSalonCurrency'
import CustomBarChart from '../ClientDashboard/BarChart'
import DynamicTable from '../ClientsTable'
import { collaboratorsData, TableCollaborateurData } from '../Utils'
import { KPICardContainer, KPIGroup } from '../RendezVous/CardsSection/styles'
import { Carousel } from 'antd'
import { InfosCard } from './InfosCard'
import useMediaQuery from 'hooks/useMediaQuery'
import { elmaBreakpoints } from 'styles/theme'

export const Collaborateur = () => {
  // Les Données
  const data = [
    { name: "Emma", value: 45000 },
    { name: "Liam", value: 38000 },
    { name: "Olivia", value: 35000 },
    { name: "Noah", value: 32000 },
    { name: "Ava", value: 30000 },
  ]

  const currency = useSalonCurrency()
  const isUnderMD = useMediaQuery(`(max-width: ${elmaBreakpoints.md}px)`)
  return (
    <>
      <CustomBarChart
        prefix={currency}
        title='Acquisition de clients par mois'
        data={data}
      />
      {/* Tableau Performance des collaborateurs */}
      <DynamicTable
        data={TableCollaborateurData}
        title='Performance des collaborateurs'
      />
      {/* Cartes des collaborateurs */}

      <KPICardContainer>
        {isUnderMD ? (
          <Carousel
            dots
            arrows
            autoplay
            style={{ width: "100%", margin: "auto", paddingBottom: "1.8rem" }}>
            {collaboratorsData.map((data, index) => (
              <div key={index}>
                <InfosCard {...data} />
              </div>
            ))}
          </Carousel>
        ) : (
          <KPIGroup>
            {collaboratorsData.map((data, index) => (
              <InfosCard key={index} {...data} />
            ))}
          </KPIGroup>
        )}
      </KPICardContainer>
    </>
  )
}
