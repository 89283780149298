
import React from "react"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts"
import * as Styled from "./styles"
import { transformChartData } from "../Utils"

export const CustomTooltip1 = ({ active, payload, label  }) => {
  if (active && payload && payload.length) {
    return (
      <Styled.TooltipContainer>
        <Styled.Valeur1>{`${label}: ${payload[0]?.value || 0} XOF`}</Styled.Valeur1>
      </Styled.TooltipContainer>
    )
  }
  return null
}

export const CustomTooltip2 = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Styled.TooltipContainer2>
        <Styled.Rdv>{`Rendez-vous:  ${payload[1]?.value || 0}`}</Styled.Rdv>
        <Styled.Valeur>{`Révenu:  ${payload[0]?.value || 0} XOF`}</Styled.Valeur>
      </Styled.TooltipContainer2>
    )
  }
  return null
}

const staticAppointmentsData = [
  { label: "Janvier", appointments: 10 },
  { label: "Février", appointments: 15 },
  { label: "Mars", appointments: 12 },
  { label: "Avril", appointments: 20 },
  { label: "Mai", appointments: 25 },
  { label: "Juin", appointments: 18 },
  { label: "Juillet", appointments: 22 },
  { label: "Août", appointments: 30 },
  { label: "Sept", appointments: 28 },
  { label: "Oct", appointments: 35 },
  { label: "Nov", appointments: 40 },
  { label: "Déc", appointments: 45 },
]


const Chart = ({ data, rendezVous, legende, tooltip, title }) => {
  // Données dynamiques pour les revenus
  const chartData = data?.revenueByPeriod
    ? transformChartData(data.revenueByPeriod)
    : []

  // Fusionner avec les données statiques pour les rendez-vous
  const combinedData = chartData.map((item, index) => ({
    ...item,
    appointments: staticAppointmentsData[index]?.appointments || 0,
  }))

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.Title>{title}</Styled.Title>
        {legende}
      </Styled.Header>

      <Styled.ChartWrapper>
        <ResponsiveContainer width='100%' height='100%'>
          <LineChart data={combinedData} margin={{ top: -10, right: 10 }}>
            <CartesianGrid
              strokeDasharray='0'
              vertical={false}
              stroke='#E5E7EB'
            />
            <XAxis
              dataKey='label'
              axisLine={false}
              tickLine={false}
              dy={10}
              tick={{ fill: "#6B7280" }}
            />
            <YAxis
              yAxisId='left'
              padding={{ top: 20 }}
              axisLine={false}
              tickLine={false}
              tickFormatter={(value) => `${value / 1000}K`}
              dx={-10}
              interval={0}
              tick={{ fill: "#6B7280" }}
            />
            <YAxis
              yAxisId='right'
              orientation='right'
              padding={{ top: 20 }}
              axisLine={false}
              tickLine={false}
              tickFormatter={(value) => `${value}`}
              dx={10}
              interval={0}
              tick={{ fill: "#6B7280" }}
            />
            <Tooltip content={tooltip} />
            <Line
              yAxisId='left'
              type='monotone'
              dataKey='value'
              stroke='#65376A'
              strokeWidth={3}
              dot={false}
              activeDot={{
                r: 5,
                stroke: "#65376A",
                fill: "white",
              }}
            />
            {rendezVous}
          </LineChart>
        </ResponsiveContainer>
      </Styled.ChartWrapper>
    </Styled.Container>
  )
}

export default Chart
