import { CardClient } from "components/StatistiquesDashboard/Cards"
import { kpiRdvData, kpiRdvTotaux } from "components/StatistiquesDashboard/Utils"
import * as Styled from "./styles"
import { elmaBreakpoints } from "styles/theme"
import useMediaQuery from "hooks/useMediaQuery"
import { Carousel } from "antd"

export const CardsSection = () => {
  const isUnderMD = useMediaQuery(`(max-width: ${elmaBreakpoints.md}px)`)
  return (
    <>
      <Styled.KPICardContainer>
        {isUnderMD ? (
          <Carousel
            dots
            arrows
            autoplay
            style={{ width: "100%", margin: "auto", paddingBottom: "1.8rem" }}>
            {kpiRdvData.map((data, index) => (
              <div key={index}>
                <CardClient {...data} />
              </div>
            ))}
          </Carousel>
        ) : (
          <Styled.KPIGroup>
            {kpiRdvData.map((data, index) => (
              <CardClient key={index} {...data} />
            ))}
          </Styled.KPIGroup>
        )}
      </Styled.KPICardContainer>

      <Styled.KPICardContainer>
        {isUnderMD ? (
          <Carousel
            dots
            arrows
            autoplay
            style={{ width: "100%", margin: "auto", paddingBottom: "1.8rem" }}>
            {kpiRdvTotaux.map((data, index) => (
              <div key={index}>
                <CardClient {...data} />
              </div>
            ))}
          </Carousel>
        ) : (
          <Styled.KPIGroup>
            {kpiRdvTotaux.map((data, index) => (
              <CardClient key={index} {...data} />
            ))}
          </Styled.KPIGroup>
        )}
      </Styled.KPICardContainer>
    </>
  )
}
