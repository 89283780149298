import { useSalonServices } from "hooks/useSalonServices";
import { SERVICES_REDUCER_ACTION_TYPE, servicesReducer } from "./reducer";
import { useQueryClient } from "react-query";
import useSalonId from "hooks/useSalonId";

const { createContext, useReducer, useContext, useEffect, useMemo } = require("react");

const ServiceContext = createContext()

export const ServiceContextProvider = ({ children }) => {
    const { isLoading, isError, salonsServices: data } = useSalonServices()
    const [servicesState, dispatch] = useReducer(servicesReducer, [])
    const queryClient = useQueryClient()
    const salonId = useSalonId()
    
    // useEffect(() => {
    //     queryClient.invalidateQueries(["services", salonId]);
    // }, [])


    useEffect(() => {
        // ! we set the initial state of servicesReducer
        dispatch({
            type: SERVICES_REDUCER_ACTION_TYPE.SET_SETVICES,
            payload: data
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    const memoValue = useMemo(() => {
        return { servicesState, isLoading, isError, dispatchServices: dispatch }
    }, [isError, isLoading, servicesState])

    return <ServiceContext.Provider value={memoValue} >{children}</ServiceContext.Provider>
}

export const useServicesContext = () => {
    const ctx = useContext(ServiceContext)

    if (!ctx) throw new Error("The component must be wrapped in ServiceContextProvider")

    return ctx
}