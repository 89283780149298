import styled from "styled-components"

export const ChartContainer = styled.div`
  width: 100%;
  margin: 20px auto;
  padding:20px;
  border: 1px solid #ccc;
  border-radius: 8px;

`
export const Titre = styled.h1`
  font-size: 17px;
  color: #252c32;
  font-weight: 500;
`
// Styles pour le Tooltip personnalisé
export const TooltipContainer = styled.div`
  background-color: #65376a;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
  height: 60px;
  padding: 4px 12px;
  border-radius: 6px;
  transform: translateY(-10px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

export const Valeur = styled.span`
  font-size: 14px;
  font-weight: 500;
`;
export const Label = styled.span`
  font-size: 14px;
  font-weight: 500;
`;
