import styled from "styled-components"

export const ChartContainer = styled.div`
  width: 100%;
  margin: 1.5rem auto;
  border: 1px solid #ccc;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
`

export const ChartTitle = styled.h3`
  text-align: left;
  margin-bottom: 20px;
  font-size: 1.2em;
  color: #333;
`

export const CustomTooltipContainer = styled.div`
  background-color: #65376a;
  color: white;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #fff;
  transform: translateY(-60px); 
`
